import { NextSeo } from 'next-seo';
import {
  useHomepageAnnouncementsAndUpdatesQuery,
  MediaAnnouncement,
  MediaUpdate,
} from '@/apollo/generated';
import { CompanyOverviewChart } from '@/components/analysis/CompanyOverviewChart';
import Banner from '@/components/announcements/banner';
import Discover from '@/components/home/discover';
import LatestAnnouncements from '@/components/home/latest-announcements';
import QuickLinks from '@/components/home/quick-links';
import PageHeader from '@/components/layouts/page-header';
import { useCurrentCompany } from '@/contexts/current-company-context';
import { useWebsite } from '@/contexts/website-context';

interface HomeProps {
  isUK: boolean;
}

const Home: React.FC<HomeProps> = ({ isUK }) => {
  const {
    currentCompany: {
      hub,
      name: companyName,
      ticker: { listingKey, marketKey },
    },
    currentCompanyMarketKey,
    currentCompanyTicker,
    translate,
  } = useCurrentCompany();

  const { renderContentInLayout } = useWebsite();

  const { data, loading } = useHomepageAnnouncementsAndUpdatesQuery({
    variables: {
      hub,
    },
  });

  const {
    latestAnnualReport,
    latestInvestorPresentation,
    latestQuarterlyCashflowReport,
    latestUpdate,
  } = data?.homepageAnnouncementsAndUpdates
    ? data.homepageAnnouncementsAndUpdates
    : {
        latestAnnualReport: null,
        latestInvestorPresentation: null,
        latestQuarterlyCashflowReport: null,
        latestUpdate: null,
      };

  const showDiscoverSection =
    !isUK &&
    (latestAnnualReport ||
      latestInvestorPresentation ||
      latestQuarterlyCashflowReport ||
      latestUpdate);

  return renderContentInLayout(
    <>
      <NextSeo
        description={`Welcome to ${companyName}'s (${currentCompanyMarketKey}:${currentCompanyTicker}) investor hub. View and interact with market data, announcements, annual reports, and investor presentations`}
        title={`${companyName} investor hub | ${currentCompanyMarketKey}:${currentCompanyTicker}`}
      />
      <PageHeader headerText={`Welcome to the ${companyName} investor hub`} />

      <Banner />

      <div className="my-12 flex flex-col gap-12 md:my-16 md:gap-16">
        {!isUK && (
          <>
            <LatestAnnouncements
              announcements={
                data?.homepageAnnouncementsAndUpdates
                  ?.latestTwoAnnouncements as MediaAnnouncement[]
              }
              loading={loading}
            />
            <QuickLinks />
          </>
        )}

        <CompanyOverviewChart
          isUK={isUK}
          listingKey={listingKey}
          marketKey={marketKey}
          translate={translate}
        />

        {showDiscoverSection && (
          <Discover
            latestAnnualReport={latestAnnualReport as MediaAnnouncement}
            latestPresentation={latestInvestorPresentation as MediaAnnouncement}
            latestQuarterlyCashflowReport={
              latestQuarterlyCashflowReport as MediaAnnouncement
            }
            latestUpdate={latestUpdate as MediaUpdate}
            loading={loading}
          />
        )}
      </div>
    </>
  );
};

export default Home;
