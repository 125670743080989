import {
  ForwardRefExoticComponent,
  PropsWithoutRef,
  RefAttributes,
  SVGProps,
} from 'react';
import { ArrowRightIcon } from '@heroicons/react/outline';
import {
  DocumentChartBarIcon,
  PresentationChartLineIcon,
  MegaphoneIcon,
} from '@heroicons/react-v2/24/solid';
import clsx from 'clsx';
import dayjs from 'dayjs';
import Image from 'next/image';
import Link from 'next/link';
import { LinkButton, PlayButtonIcon, Typography } from '@leaf/ui';
import {
  Maybe,
  MediaAnnouncement,
  MediaUpdate,
  MediaUpdateType,
} from '@/apollo/generated';
import RichContent from '@/components/utils/rich-content';
import routes from '@/utils/routes';

interface Props {
  announcement?: MediaAnnouncement;
  layout?: 'horizontal' | 'vertical';

  update?: MediaUpdate;
}

interface InnerProps {
  Icon?: ForwardRefExoticComponent<
    PropsWithoutRef<SVGProps<SVGSVGElement>> & {
      title?: string;
      titleId?: string;
    } & RefAttributes<SVGSVGElement>
  >;
  date: string;
  href: string;
  imageUrl?: Maybe<string>;

  textContent?: Maybe<string>;
  title: string;
  type?: string;
  videoSocialUrl?: Maybe<string>;
  videoUrl?: Maybe<string>;
}

const DiscoverCard: React.ComponentType<Props> = ({
  announcement,
  layout = 'horizontal',
  update,
}) => {
  const props: InnerProps | null = announcement
    ? {
        Icon: announcement.subtypes.includes('03026')
          ? PresentationChartLineIcon
          : announcement.subtypes.includes('03001')
          ? DocumentChartBarIcon
          : undefined,
        date: announcement.postedAt,
        href: routes.interactiveAnnouncement.href(announcement.id),
        imageUrl: announcement.thumbnailUrl,
        textContent: announcement.summary,
        title: announcement.header,
        type: 'announcement',
        videoSocialUrl: announcement.socialVideoUrl,
        videoUrl: announcement.videoUrl,
      }
    : update
    ? {
        Icon: MegaphoneIcon,
        date: update.postedAt || '',
        href: routes.activityUpdate.href(update.slug),
        imageUrl: update.includedTypes?.includes(MediaUpdateType.Image)
          ? update.attachments?.[0]?.thumbnailUrl ||
            update.attachments?.[0]?.url
          : null,
        textContent: update.content?.content,
        title: update.title,
        type: 'update',
        videoSocialUrl: update.includedTypes?.includes(MediaUpdateType.Video)
          ? update.attachments?.[0]?.url ||
            update.attachments?.[0]?.thumbnailUrl
          : null,
        videoUrl: null,
      }
    : null;

  if (!props) return null;
  return (
    <Link
      className={clsx(
        'flex flex-col gap-6 overflow-hidden rounded-lg border bg-hubs-background p-4 md:p-6',
        layout === 'horizontal' ? 'md:flex-row' : ''
      )}
      href={props.href}
    >
      <div
        className={clsx(
          'flex w-full shrink-0 items-center justify-center overflow-hidden bg-company-accent',
          layout === 'horizontal' ? 'md:w-[30%]' : ''
        )}
      >
        <div
          className={clsx(
            'relative h-full w-full pt-[39%]',
            layout === 'horizontal' ? 'md:pt-[67%]' : 'md:pt-[35%]'
          )}
        >
          {props.videoUrl || props.videoSocialUrl ? (
            <div className="absolute inset-0">
              {props.videoUrl ? (
                <video
                  className="pointer-events-none absolute left-1/2 top-1/2 z-10 h-full w-full -translate-x-1/2 -translate-y-1/2"
                  src={`${props.videoUrl}?autoplay=0&showinfo=0&controls=0`}
                />
              ) : props.videoSocialUrl ? (
                <iframe
                  className="pointer-events-none absolute left-1/2 top-1/2 z-10 h-full w-full -translate-x-1/2 -translate-y-1/2"
                  src={`${props.videoSocialUrl}?autoplay=0&showinfo=0&controls=0`}
                />
              ) : null}
              <div className="absolute left-1/2 top-1/2 z-10 h-10 w-10 -translate-x-1/2 -translate-y-1/2 fill-company-primary">
                <PlayButtonIcon className="h-10 w-10 fill-inherit" />
              </div>
            </div>
          ) : props.imageUrl ? (
            <>
              <div className="absolute inset-0 z-10 bg-black opacity-5" />
              <Image
                alt={props.title}
                className="absolute left-0 top-0 h-auto w-full"
                height={200}
                src={props.imageUrl}
                style={{ objectFit: 'cover' }}
                width={300}
              />
            </>
          ) : props.Icon ? (
            <div className="absolute left-[50%] top-[50%] flex h-[52px] w-[52px] -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full bg-company-primary-text fill-company-primary">
              <props.Icon className="h-8 w-8 fill-inherit" />
            </div>
          ) : null}
        </div>
      </div>
      <div
        className={clsx(
          'flex h-full w-full flex-col justify-between',
          layout === 'horizontal' ? 'md:w-2/3' : ''
        )}
      >
        <div className="mb-4">
          <Typography className="mb-2 text-hubs-primary" variant="button">
            {props.title}
          </Typography>
          <Typography
            className="mb-4 text-hubs-secondary md:mb-2"
            variant="fine-print"
          >
            Released {dayjs(props.date).format('DD MMM YYYY')}
          </Typography>
          {props.textContent ? (
            <div className="mb-2 line-clamp-2 max-h-12 overflow-hidden font-body text-sm text-hubs-secondary">
              <RichContent plaintext content={props.textContent} />
            </div>
          ) : null}
        </div>
        <LinkButton className="group flex items-center gap-2 font-body text-sm !text-company-primary hover:no-underline">
          View {props.type}
          <ArrowRightIcon className="size-4 text-inherit transition-transform group-hover:translate-x-1/4" />
        </LinkButton>
      </div>
    </Link>
  );
};

export default DiscoverCard;
